$black: #00171f;
$mid-blue: #007ea7;
$dark-blue: #003459;
$light-blue: #00a8e8;
$background: #191919;
$gradient1: #fc466b;
$gradient2: #3f5efb;
body{
position: relative;
overflow-x: hidden;

}
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
button {
  padding: 10px 20px;
  margin: 0.4rem 1rem;
  background-color: transparent;
  outline: none;
  border: solid 2px $gradient1;
  color: #fff;
  border-radius: 5px;
  transition: 0.3s;
}
button:hover {
  background-color: $gradient1;
}
body {
  color: #fff;
  background-color: $background;
  height: 100%;
  font-family: "Lato", sans-serif;
}
html {
  height: 100%;
}
.width {
  width: 80%;
  margin: auto;
}

//Navbar
.navbar {
  padding: 2rem 1rem;
}
.logo {
  width: 4rem;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avatar::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($gradient1),
    to($gradient2)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: initial;
}
.avatar {
  font-size: 2rem;
}
.dropdown {
  position: absolute;
  right: 7%;
  user-select: none;
  color: #fff;
  margin-top: -2rem;
  padding: 0;
  list-style: none;
  transition: 0.3s;
  border-radius: 5px;
  z-index: 2000;
  background-color: #191919;
  
  li {
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 5px;
    cursor: pointer;
  }
  li:hover {
    background-color: $gradient1;
    border-radius: 5px;
  }
         
}
  
.buttons {
  display: flex;
  gap: 1.5rem;
}
.login {
  font-weight: 400;
  border-bottom: solid 2px $gradient1;
  padding: 0.3rem;
  transition: 0.3s;
  cursor: pointer;
}

.login:hover {
  border-bottom: solid 2px $gradient2;
}
.register:hover {
  border-bottom: solid 2px $gradient2;
}
.register {
  font-weight: 400;
  padding: 0.3rem;
  border-bottom: solid 2px $gradient1;
  transition: 0.3s;
  cursor: pointer;
}
//Intro------------------------------------------------------------------------------------------
.intro {
  height: 70vh;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20vh;
}
.newpostloading{
  display: none;
}
.postera {
  width: fit-content;
  &Text {
    width: fit-content;
    background-image: linear-gradient(to right, $gradient1, $gradient2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 7rem;
    letter-spacing: 0.2rem;
    margin: 0;
  }
  &Desc {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    float: right;
    opacity: 0.8;
    margin-right: -4rem;
  }
}

.postato{
  margin-top:1rem;
  width: 20rem;
  margin-right: 5rem;
 
}
@keyframes move{
  0%{
      transform: scale (1);
  transform: rotate(0deg);
      border-radius: 1rem;
  }50%{
    transform: scale (1.1);
  }100%{
    transform: rotate(360deg);
    border-radius: 50%/100px 100px 0 0;
  }
}
//Blog
.blog {
  cursor: pointer;
  margin-top: 10vh;
  &_photo {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    object-fit: cover;
    width: 22vw;
    height: 28vh;
    opacity: 0.8;
    border-radius: 5px;
  }
  &_desc {
    width: 20vw;
    font-weight: lighter;
    text-align: center;
  }
  &_name {
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    background-image: linear-gradient(to right, $gradient1, $gradient2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.blogBoxes, .manage_posts {
  display: grid;
  width: 80%;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  padding-bottom: 30vh;
  gap:5rem;
  justify-content: center;
  
}
//Login
.login {
  &background {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.8;
  }
  &box {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 30vw;
    margin: auto;
    position: fixed;
    top: 20%;
    padding-bottom: 3rem;
    left: 20%;
    right: 20%;
    border: solid 3px $gradient1;
    background-color: #140b0b;
    z-index: 1000;
    opacity: 1;
    border-radius: 10px;
  }
  &h1 {
    text-align: center;
    padding-top: 1rem;
  }
  &inputs {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
  }
  &button {
    width: 10vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 2rem;
  }
}
input,
textarea {
  padding: 10px 20px;
  border: none;
  outline: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: solid 3px $gradient1;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin-top: 0.5rem;
  resize: none;
}
input:focus,
textarea:focus {
  border: solid 3px $gradient2;
}
label {
  margin-top: 1rem;
}
.notlogged {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.underline {
  text-decoration: underline;
  margin-left: 0.5rem;
  cursor: pointer;
}
.regbackground,
.newPostbackground, .loading_overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
}
.loading_overlay{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1rem;
 
}
.regload{
  display: none;
}
.cube1, .cube2, .cube3{

  width: 3rem;
  height: 1rem;
  background-color: $gradient1;
  transform:
      perspective(75em) rotateX(18deg);
    box-shadow:
      rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
      rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}
.cube1{
    animation: jump1 1s infinite;
}
.cube2_jump{
animation: jump2 1s infinite;
}
.cube3_jump{
animation: jump2 1s infinite;
}

@keyframes jump1 {
  0%{
    margin-top: 0;
  }
  50%{
    margin-top:-2rem
  }

}
@keyframes jump2 {
  0%{
    margin-top: 0;
  }
  50%{
    margin-top:-3rem
  }

}
@keyframes jump3 {
  0%{
    margin-top: 0;
  }
  50%{
    margin-top:-4rem
  }

}
.regbox {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 30vw; 
  height: fit-content;
  margin: auto;
  position: fixed;
  top: 20%;
  padding-bottom: 2rem;
  left: 20%;
  right: 20%;
  background-color: #140b0b;
  border: solid 3px $gradient1;
  z-index: 9998;
  opacity: 1;
  border-radius: 10px;
}
//Alert
.alert {
  &box {
    color: #fff;
  }
  &background {
    animation: slideIn 0.3s;
    padding: 20px 40px;
    background-color: $background;
    text-align: center;
    border-radius: 10px;
    opacity: 0.9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: fixed;
    right: 1%;
    top: 2%;
    z-index: 9999;
  }
  &p {
    color: #fff;

    font-size: 17px;
  }
}
.circle {
  font-size: 24px;
}

@keyframes slideIn {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
//New Post

.newPostbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog_form {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30vw;
  margin: auto;
}

.upload {
  &_button {
    margin: auto;
    margin-top: 1rem;
    border: none;
    border-bottom: solid 1px #3f5efb;
  }
  &_button:hover {
    background-color: #3f5efb;
  }
}

a {
  text-decoration: none;
  color: #fff;
}
h2 {
  color: $dark-blue;
}

.manage {
  margin-top: 5vh;

  &_h1 {
    text-align: center;
  }

}

.deletePost {
  color: red;
}

.post {
  &_name {
    display: block;
    align-self: center;
    text-align: center;
    width: fit-content;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 5px;
    max-width: 50vw;
    background-image: linear-gradient(to right, $gradient1, $gradient2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px #00000000;
          
          
  }
  &_name:focus{
    outline:  solid 1px $gradient1;
    border: none;
  }
  &_photo_big {
    height: 22rem;
    margin: auto;
    margin-top: 3rem;
  }
  &_photo_upload{
      height: 30vh;
      margin: auto;
  }
  &_desc {
    max-width: 80vw;
    text-align: center;
    line-height: 2rem;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 5px;
    z-index: 1000;
    background-color: $background;
    overflow: hidden;
    word-break: break-word;
 
  white-space: wrap;
  }
  &_desc:focus {
    outline: solid 1px $gradient1;
    border: none;
  }

  &_author {
    display: flex;
    justify-content: flex-end;
    margin-left:0.5rem;

  }
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.postEditMenu {
  position: absolute;
  top: 40%;
  bottom: 40%;
  background-color: #fff;
  width: 80vw;
  height: 20vh;
}
.postEditMenuBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.8;
}
.fa-arrow-left {
  font-size: 3rem;
  margin-left: 5rem;
  margin-top: -3rem;
  padding-bottom: 3rem;
}
.fa-trash{
  color:$gradient1;
  margin-left: auto;
  opacity: 0.9;
}
.editPhoto {
  width: 30rem;
  margin: auto;
  border-radius: 10px;
}
.editMenu {
  display: flex;
  flex-direction: column;
}

.tool {
  &_legend {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  &_delete {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    cursor: pointer;
  }
}
.save_changes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;
}
.edit_picture {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;
}
.pen {
  font-size: 2rem;
}

@media (max-width: 500px) {
  .blogBoxes, .manage_posts {
    grid-template-columns: 1fr;
  }
  .blog {
    width: 70vw;
    &_photo {
      width: 60vw;
    }
    &_desc {
      width: 100%;
    }
  }

  .btn {
    width: 60vw;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: rotate(1);
  }
}

.noPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  transition: 0.3s;
}

.addphotosvg {
  transition: 0.3s;
}
.photo_shown {
  position: absolute;
  background-color: #191919a2;
  padding: 1rem;
  font-size: 20px;
  transition: 0.3s;
  border-radius: 1rem;
}
.post_photo_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addphotosvg:hover {
  transform: scale(1.1);
}
.importImage {
  display: none;
}
.uploadbtn {
  margin: auto;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from($gradient1),
    to($gradient2)
  );
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.3s;
  cursor: pointer;
}
.uploadbtn:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from($gradient2),
    to($gradient1)
  );
  transform: scale(1.1);
}
.fa-arrow-up-from-bracket {
  font-size: 0.8rem;
}
.page_header{
  width: fit-content;
  margin: auto;
  margin-bottom:2rem;
  font-size: 2.6rem;
  border-bottom: solid 2px #fff;
  padding:0 2rem;
  padding-bottom:0.6rem;
}
.blank{
  border: solid 1px rgb(116, 116, 116);
  border-radius: 5px;
  height: 15rem;
    width: 26rem;
  margin: auto;
}
.editable_name, .editable_desc{

  border-radius: 0;
  margin-top:0.7rem;
}
.post_avatar{
    background: -webkit-gradient(linear,
      left top,
      left bottom,
      from($gradient1),
      to($gradient2));
   background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial;
}
.author{
  display: flex;
  align-items: center;
  
    width: 35rem;
    margin: auto;
    justify-content: flex-end;
}
.toolbar{
  border: solid 1px $gradient2;
  padding: 1rem;
  width: 30vw;
  margin: auto;
  border-radius: 5px;
}


.footer{
 position: relative;
 margin-top:10vh;
 bottom: 0;
 background-color: #0000001e;
  &_content{
    width: 80vw;
    margin: auto;
    padding:3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &ul{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
  }
  &ul li{
    width: fit-content;
    padding-bottom:0.1rem;
    border-bottom: solid 1px #fff;
  }
}
.description{
  width: 60vw;
  margin: auto;
  line-height: 1.6rem;
  margin-bottom:10vh;
}
.desc_text{
 width: fit-content;
  margin: auto;
  text-align: justify;
}
.desc_link{
  border-bottom: solid 1px #fff;
}
.settings{
  &_box{
    width: fit-content;
    height: fit-content;
    padding:1rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transition: 0.3s;
      cursor: pointer;
     
      
  }
  &_box:hover{
    transform: scale(1.2);
  }
  &_h2{
      background: -webkit-gradient(linear,
        left top,
        left bottom,
        from($gradient1),
        to($gradient2));
        background-clip: text;
      -webkit-text-fill-color: transparent;
      display: initial;
      -webkit-box-decoration-break: clone;
  }
  display: flex;
  justify-content: center;
  gap:2rem;
 height: 50vh;
}
.change_password{
  width: fit-content;
margin: auto;
margin-top:4vh;
& input{
  width: 30vw;
}
& h3{
  margin-top:2rem;
}
&_btn{
  width: fit-content;
  padding:0.8rem 2rem;
 margin: auto;
 margin-top:2rem;
}
display: flex;
flex-direction: column;
justify-content: center;
padding-bottom:5vh;
}
.comment_box, .insert_comment_box{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding:1rem 3rem;
}

.reply_comment_box{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding:0.3rem 2rem;
    width: 87%;
    margin-left: auto;
    margin-top:-2rem;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    background-color: #fc466a0e;
    
}


.comment_reply_line{
  border-right: dotted 2px $gradient1;
  padding-bottom:1rem;
  z-index: 10;
  width: fit-content;
  margin: auto;
  margin-top:-1.3rem;

  border-radius: 5px;
}
.insert_comment_box{
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom:3rem;
}
.comment_box_div{
  display: flex;
  flex-direction: column;
  gap:3rem;
}
.comment_post{
  display: flex;
  width: fit-content;
  margin-left: auto;
  align-items: center;
  gap:1rem;
  cursor: pointer;
  transition: 0.3s;
}
.writereply{
  user-select: none;
}
.comment_post:hover{
  color: $gradient2;
}
.insert_comment{
  border: none;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  height: auto;
}
.insert_comment:focus{
  border: none !important;
}
.comments{
  display: flex;
  flex-direction: column;
  gap:3rem;
 
}
.comment{
  &_author{
    display: flex;
    align-items: center;
  }
        &_author_name {
          margin-left: 0.5rem;
        }
}
.reactions{
  display: flex;
  align-items: center;
  gap:1rem;
  justify-content: flex-end;
  margin-right: 1rem;

}
.loginbox{
  z-index: 9998;
}



.fa-thumbs-up, .fa-thumbs-down{
  font-size: 1.3rem !important;
}

.footerul{
  list-style-type: none;
}


@media(max-width:1725px) {
  .regbox {
      width: 35vw;
      bottom: 18%;
    }
         .intro {
           height: 60vh;
         }
         .blog_photo{
           width: 20rem;
           height: 13rem;
         }
         .blogBoxes{
           gap:3rem;
         }
                 .blog_desc {
                   width: fit-content;
                 }
              
}

@media(max-width:1244px){
  .intro{
    
    height: 50vh;
  }
  
  .posteraText{
    font-size: 6rem;
  }
  .anim_box1{
    width: 9rem;
    margin-top:-1rem;
  }

  .blogBoxes, .manage_posts{
    grid-template-columns: 1fr 1fr;

   align-items: center;
    margin-top:5rem;
    gap:3rem;

  }
  .blog{
    margin: auto;
   
  }
  .blog_photo{
    width: 20rem;
    height: 10rem;
  }

  .post_photo_big{
    width: 35rem;
    height: 19rem;
 
   margin-top: 3rem;
  }

  .blank{
    margin: auto;
  }
  .post{
    width: 60%;
  }
  .loginbox{
    width: auto;
  }
  .regbox{
    width: auto;
    bottom:15%;
  }
}


@media(max-width:1064px) {
    .blog_photo{
      height: 11rem;
    }
        .postato {
          margin-top: 1rem;
          width: 15rem;
          margin: auto;
        }
        .change_password input{
          width: 60vw;
        }
  }

  

         @media(max-width:826px) {
            .blogBoxes, .manage_posts {
                grid-template-columns: 1fr;
              }
           .intro{
             gap:6rem;
             height: 40vh;
           }
           .anim_box1{
             margin-top:-3rem;
             width: 8rem;
           }
                        .posteraText {
                          font-size: 6rem;
                        }
                        .posteraDesc{
                          font-size: 1rem;
                        }
                        .blog_photo{
                          height: 12rem;
                        }
                        .post_photo_big{
                          width: 25rem;
                          height: 15rem;
                        }
                                                     .postato {
                                                       margin-top: 1rem;
                                                       width: 10rem;
                                                       margin: auto;
                                                     }
                                                     .author{
                                                      width: 25rem;
                                                     }
         }

                     @media(max-width:694px) {
                      .posteraText {
                          font-size: 4rem;
                        }
                     }

             @media(max-width:572px) {
               #root{
                 overflow-x: hidden;
               }
               
               .intro{
                margin-left:2rem;
                height: 30vh;
               }
              .posteraText {
                  font-size: 4rem;
                }
                .footer_content{
                  width: auto;
                  font-size: 16px;
                  padding: 3rem 0;
                }
                .settings{
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                &_box{
                  width: 50vw;
                  text-align: center;
                }
                }
                .fa-arrow-left{
                  position: absolute;
                  margin-top:-7rem;
                  margin-left: 2rem;
                }
                                .anim_box1 {
                                  margin-top: -3rem;
                                  width: 6rem;
                                }
                                .post_photo_big{
                                  width: 20rem;
                                  height: 10rem;
                                  align-items: center;
                                }
                                .author{
                                    width: 20rem;
                                }
                                .blank{
                                  width: 20rem;
                                    height: 10rem;
                                    align-items: center;
                                }
                                .page_header{
                                  font-size: 2rem;
                                  text-align: center;
                                  line-height: 2rem;
                                }
                                .post_name{
                                  font-size: 1.8rem;
                              
                                }
                                .tool_legend{
                                  width: 100%;
                                  display: grid;
                                  grid-template-columns: 1fr 1fr;
                                  justify-content: center;
                                  gap:0;
                                }
                                .save_changes{
                                  grid-column: 1/ -1;
                                }
                                .post{
                                  width: 80%;
                                }
                                textarea, input{
                                  font-size: 16px;
                                }
                                .footer_content{
                                  flex-direction: column;
                                  align-items: center;
                                 justify-content: center;
                                }
                                .footerul{
                                  flex-direction: row;
                                  justify-content: space-around;
                                  margin-right: 2rem;
                                }
             }
             

                            @media(max-width:489px) {
                              .intro{
                                  flex-direction: column-reverse;
                                  gap:0;
                                  margin-top:10vh;
                                  margin-bottom: 5rem;
                                  justify-content: flex-start;
                              }
                               .buttons{
                               gap:0;
                               }        
                               .login{
                                margin-right:1rem;
                               } 
                              .blog_photo{
                                height: 10rem;
                               
                              }
                              .post_photo_big{
                                width: 19rem;
                                height: 11rem;
                              }
                              .blank{
                                  width: 19rem;
                                    height: 11rem;
                              }
                              .loginbox, .regbox{
                                left:5%;
                                right:5%;
                                top:5%;
                                bottom: auto;
                                
                              }
                              .post{
                                width: 80%;
                                gap:0;
                                
                              }
                              .post_desc{
                                margin-top:-1rem;
                              }
                              .post_name{
                                width: 100%;
                              }
                              .blog{
                                overflow: hidden;
                              }
                              .loginbutton{
                               padding: 1rem 3rem;
                              }
                              .logininputs{
                                justify-content: center;
                              }
                              .comment_box, .reply_comment_box{
                                padding: 0.3rem 1.7rem;
                               
                              }
                              .posteraDesc{
                                float: left;
                              }
                              .description{
                                width: 80vw;
                              }
                              .footer_content{
                                justify-content: space-between;
                              }
                            }